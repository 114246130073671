 


<template>
  <div style="padding-left: 10px">
    <vx-card class="mb-4">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <div class="w-full">
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
              <label for="topic" class="text-lg font-medium mb-2 mt-4 sm:mt-0"
                >Topic: {{ subTopic.topic ? subTopic.topic : topicName }}</label
              >
            </p>
            <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
              <label
                for="subTopic"
                class="text-lg font-medium mb-2 mt-4 sm:mt-0"
                >Sub Topic</label
              >
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="subTopic"
                v-model="subTopic.subTopic"
                vs-w="4"
              />
              <span class="text-danger text-sm">{{
                errors.first("subTopic")
              }}</span>
            </p>
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
            >
              <vs-row vs-justify="flex-start" class="mt-10">
                <vs-button
                  color="success"
                  type="border"
                  v-if="subTopic._id"
                  @click="updateDetails()"
                  >Save Changes</vs-button
                >
                <vs-button
                  class="ml-5"
                  color="success"
                  v-if="subTopic._id"
                  @click="QAList()"
                  >Questions Answers</vs-button
                >
                <vs-button
                  color="success"
                  v-if="!subTopic._id"
                  @click="addNew()"
                  >Save</vs-button
                >
                <vs-button
                  color="primary"
                  type="border"
                  class="ml-4"
                  @click="btSubTopic()"
                  >Back to Sub Topics</vs-button
                >
              </vs-row>
            </vs-row>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";

export default {
  name: "FAQsSubTopic",

  data: () => ({
    selectedCard: "",
    inProgress: false,
    subTopic: {},
    subtopicsid: "",
    topicsid: "",
    topicName: "",
  }),
  computed: {
    validateForm() {
      return !this.errors.any() && this.subTopic.subTopic != "";
    },
  },
  methods: {
    ...mapActions("admin", [
      "getFaqsById",
      "getFaqsSubTopicById",
      "createFAQsSubTopic",
      "updateFAQsSubTopic",
    ]),
    getFaqsDetail() {
      let self = this;
      this.getFaqsSubTopicById(this.subtopicsid).then((res) => {
        this.subTopic = res.data.data[0];
      });
    },
    getFaqsTopic() {
      let self = this;
      this.getFaqsById(this.topicid).then((res) => {
        this.topicName = res.data.data.topic;
      });
    },

    addNew() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            topicid: this.topicid,
            subTopic: this.subTopic.subTopic,
          };
          this.createFAQsSubTopic(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "Added New Sub Topic",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push({
                name: "SubTopicList",
                params: { id: this.topicid },
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
    updateDetails() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            topicsid: this.topicid,
            subtopicsid: this.subtopicsid,
            subTopic: this.subTopic.subTopic,
          };

          this.updateFAQsSubTopic(data).then((res) => {
            if (res.data.data) {
              this.$vs.notify({
                title: "Updated Sub Topic",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push({
                name: "SubTopicList",
                params: { id: this.topicid },
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
    QAList() {
      this.$router.push({
        name: "QAList",
        params: { id: this.topicid, sid: this.subtopicsid },
      });
    },
    btSubTopic() {
      this.$router.push({ name: "SubTopicList", params: { id: this.topicid } });
    },
  },

  created() {
    this.topicid = this.$route.params.id;
    this.subtopicsid = this.$route.params.subid;
    if (this.subtopicsid) {
      this.getFaqsDetail();
    } else {
      this.subTopic = {};
      this.getFaqsTopic();
    }
  },
};
</script>
  
<style>
#uploadBtn {
  display: none;
}
</style>
